import type { AppProps } from "next/app";
import Footer from "@/components/ui/Footer";
import NavBar from "@/components/ui/NavBar";
import "@/styles/globals.css";
import localFont from "next/font/local";
import Head from "next/head";
import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import Image from "next/image";
import TopFooter from "../public/images/background/curve.png";
import { Analytics } from "@vercel/analytics/react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Toaster } from "@/components/ui/sonner"
import { SpeedInsights } from '@vercel/speed-insights/next';
import { Inter } from 'next/font/google'
import Banner from "@/components/animated/banner/Banner";

export const albra = localFont({
  src: "../public/fonts/Albra/Albra.ttf",
});

const inter = Inter({ subsets: ['latin'] })

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

 const showBanner = !(router.pathname === '/contact' || router.pathname.startsWith('/projects'));

  return (
    <GoogleReCaptchaProvider reCaptchaKey={`${process.env.NEXT_PUBLIC_V3_SITE_KEY}`}>
      <Head>
        <meta
          name="viewport"
          content="width-device-width, initial-scale=1, viewport-fit=cover"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="icon" href="/logo.png" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="google-site-verification"
          content="dlNDtSuXHSNulfPuDCA1D-DCOFE5OdplkiZkypH4WgI"
        />
        <meta name="yandex-verification" content="d58d676f12fda460" />
        <meta name="robots" content="all" />
      </Head>
      <main
        className={`${inter.className} bg-lightBackground dark:bg-darkBackground w-full min-h-screen`}
      >
        <NavBar />
        {showBanner && (
          <Banner text="✦&nbsp; &nbsp;New Website Coming Soon!" />
        )}
        <AnimatePresence mode="wait">
          <Component key={router.asPath} {...pageProps} />
        </AnimatePresence>
        <Analytics />
        <SpeedInsights />
        <Toaster 
          expand={false}
          />
        <Image
          src={TopFooter}
          className="pt-10 invert brightness-50 dark:grayscale dark:invert-0 dark:brightness-100 w-full"
          alt=""
        />
        <Footer />
      </main>
    </GoogleReCaptchaProvider>
  );
}
