import { motion } from "framer-motion";

const marqueeVariants = {
  animate: {
    x: [0, -800],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 18,
        ease: "linear",
      },
    },
  },
};

interface BannerText {
  text: string;
}

const Banner = ({text} : BannerText) => {
  return (
    <div className="w-full h-fit overflow-hidden bg-primaryLight dark:bg-primaryDark py-3">
      <motion.div
        className="flex items-center whitespace-nowrap"
        animate="animate"
        variants={marqueeVariants}
      >
        {[...Array(40)].map((_, i) => (
          <p
            key={i}
            className="text-sm font-light text-white pr-2 mr-2 tracking-widest"
          >
            {text}
          </p>
        ))}
      </motion.div>
    </div>
  );
};

export default Banner;
